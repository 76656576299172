import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import { ListingPayload } from "@/interfaces/listing_payload";
import { CareerPath } from "@/interfaces/responses/career_path";
import { FavoriteJobs } from "@/interfaces/responses/favorite_jobs";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { PrincipleAssessments } from "@/interfaces/data_objects/principle_assessments";
import { AssessmentQuestions } from "@/interfaces/responses/assessment_questions";
import { SkillGap } from "@/interfaces/responses/skill_gap";
import { AppAssessments as AAssessment } from "@/interfaces/data_objects/app_assessments";
import { ViewAssessmentsOptions } from "@/interfaces/candidate/canidate_assessments";

export interface CandidateState {
  loading: boolean;
  jobs_loading: boolean;
  matching_jobs: MatchingJob[];
  upskill_courses_loading: boolean;
  job_id_for_upskill: number | null;
  toggle_job_fav_status_loading: boolean;
  upskill_courses: UpskillCourses.UpskillCoursesRoot | null;
  searched_matching_jobs: {
    jobs: MatchingJob[];
    title: string;
    active: boolean;
  };
  interviews: CandidateInterviews;
  tenant_info: TenantInfo;
  candidate_jobs: CandidateJobs;
  app_assessments: AppAssessments;
  screen_recording_supported: boolean;
  camera_recording_supported: boolean;
  eng_assessment_completed: boolean;
  eng_assessment_cancelled: boolean;
  annotation_assessment_completed: boolean;
  annotation_assessment_cancelled: boolean;
  screen_recording_permission_granted: boolean;
  camera_recording_permission_granted: boolean;
}

export interface MatchingJob {
  candidate_id: number;
  experience: string | null;
  jid: number;
  job_description: string;
  job_title: string;
  matching_data: MatchingData;
  score: number;
  sector: string;
  skills_list: string;
  employment_type: string | null;
  is_favorite?: boolean;
}

export interface UpskillCoursesActionPayload {
  response_id: string;
  job_id: string;
  gap_list: string[];
}

export interface SearchedMatchingJobsPayload {
  jobs: MatchingJob[];
  title: string;
  active: boolean;
}

export interface SearchAnyJobPayload extends ListingPayload {
  job_title: string;
}

export interface CareerPathPayload {
  uid: string;
  n: number;
}

// Action to like/dislike jobs
export enum FavJobActions {
  LIKE = "like",
  DISLIKE = "dislike"
}

export interface ToggleJobFavStatusPayload {
  job_id: number;
  action: FavJobActions;
}

// Payload for updating fav jobs status in store
export interface UpdateFavJobsStatus {
  fav_jobs: FavoriteJobs[];
}

// Payload for updating fav job status in store
export interface UpdateFavJobStatus {
  job_id: number;
  searched_matching_jobs: boolean;
  action: boolean;
}

export interface CandidateInterviews {
  search_job_loading: boolean;
  listing: CandidateInterviewsListing[] | null;
  pagination: number;
}

export interface CandidateInterviewsListing {
  interview_id: number;
  job_title: string;
  job_id: number;
  status: Interview.Status;
  date: string;
  class: string;
  readable_status: string;
}

export interface TenantInfo {
  loading: boolean;
  error: boolean;
  details: PrincipleAssessments | null; // DB principle_assessment table record
  total_questions: number; // Total questions
  progress_value: number; // Progress value
  assessment_questions: AssessmentQuestions.Root; // Get Assessment questions response
  assessment_record: AssessmentRecord[]; // Final record
  question_index: number; // Index used for assessment record
}
export interface AssessmentRecord {
  question: string;
  answerNumber: number;
  questionNumber: number;
}
export interface RegisterTenantPayload {
  tp_name: string;
}

export interface SubmitAssessmentAnswersPayload {
  answers: AssessmentAnswersPayload[];
  question_index: number;
}

export interface AssessmentAnswersPayload {
  questionNumber: number;
  answerNumber: number;
}

export enum AssessmentErrorTypes {
  QUESTIONS,
  REPORT
}

export interface FetchMatchingJobResponse {
  results: MatchingJob[];
  total: number;
}

export interface GetMatchingJobsPayload {
  candidate_id: number;
  page: number;
  limit: number;
  job_title?: string;
}
export interface MatchingData {
  gap: SkillGap.Skills[];
  matched: SkillGap.Skills[];
}

export interface MatchingJobs {
  loading: boolean;
  results: MatchingJob[];
  total: number;
  page: number;
  limit: number;
  job_title?: string;
}

export interface TopMatchingJobs {
  loading: boolean;
  results: MatchingJob[];
  total: number;
}
export interface CandidateJobs {
  matching_jobs: MatchingJobs;
  top_matching_jobs: TopMatchingJobs;
}

export interface FetchAssessmentQuestionnariesPayload {
  assessment_id: number;
}

export interface AppAssessments {
  english: AppEnglishAssessment | null;
  annotation: AppAnnotationAssessment | null;
  ss: File[];
}

export interface AppAnnotationAssessment {
  questions: AnnotationAssessmentMap[] | null;
  cancelled: boolean;
  completed: boolean;
  current_question_index: number;
}

export interface AppEnglishAssessment {
  filter: ViewAssessmentsOptions;
  speaking_completed: boolean;
  speaking: EnglishAssessmentMap[] | null;
  mcqs_completed: boolean;
  mcqs: EnglishAssessmentMap[] | null;
  written: EnglishAssessmentMap[] | null;
  cancelled: boolean;
  completed: boolean;
  current_question_index: number;
}

export interface EnglishAssessmentMap
  extends AAssessment.AssessmentQuestionnaires {
  answer: string | null;
}

export interface AnnotationAssessmentMap
  extends AAssessment.AssessmentQuestionnaires {
  answer: AnnotationAssessmentAns | null;
}

export interface AnnotationAssessmentAns {
  answer_type: AAssessment.AnswerType;
  answer: string | null | JSON;
}

export interface AssessmentChat {
  id: number;
  role: AssessmentChatRoles;
  content: string;
  created_at: string;
  picture: string;
  audio_url?: string | null;
  time?: string;
}

export enum AssessmentChatRoles {
  BOT = "assistant",
  USER = "user"
}

export interface UpdateAssessmentQuestionPayload {
  filter: ViewAssessmentsOptions;
  question_id: number;
  answer: string;
}

export interface UpdateAssessmentMcqsPayload {
  filter: ViewAssessmentsOptions;
  question_id: number;
  answer: string;
}

export interface SubmitAssessmentAnswersApiPayload {
  assessment_id: number;
  question_id: number;
  answer_type: AAssessment.QuestionType;
  answer: string | JSON;
  done?: boolean;
}
export interface SubmitAssessmentMcqsAnswersApiPayload {
  assessment_id: number;
  question_id: number;
  answer_type: AAssessment.QuestionType;
  answer: string | JSON;
  done?: boolean;
}

export interface UpdateAppAnnotationAssessmentPayload {
  question_id: number;
  answer: AnnotationAssessmentAns | null;
}

export interface SubmitAppAssessmentMediaPayload {
  assessment_id: number;
  data: FormData;
}

export interface AnnotationPartInterface {
  content: string;
  options: OptionsPart1[];
}

export interface OptionsPart1 {
  op1_selected: boolean;
  op2_selected: boolean;
  option1: string;
  option2: string;
}

export interface AnnotationAnswers {
  content?: string;
  answer: string;
  arabic?: string;
  title?: string;
  question: string;
}
export interface AssessmentAverageTimePayload {
  english_assessment_time?: string;
  annotation_assessment_time?: string;
  speaking_time?: string;
  mcq_time?: string;
  writing_time?: string;
  part1_time?: string;
  part2_time?: string;
  part3_time?: string;
  part4_time?: string;
  part5_time?: string;
  part6_time?: string;
  part7_time?: string;
  part8_time?: string;
  answer_time?: string;
}
